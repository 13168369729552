import { createRetoolEmbed } from '@tryretool/retool-embed'

const message = 'Hello world' // Try edit me
let embeddedRetool = {}

// Update header text
const header = document.querySelector('#header')
header.innerHTML = message

const body = document.querySelector('#body')
let data = { "counter": 0 }

const increaseCounter = () => {
  console.log('increaseCounter', data["counter"])
  data['counter'] = data['counter'] + 1
  embeddedRetool.data = data 
}

const container = document.createElement('div')
container.className = 'iframeContainer'
body.appendChild(container)
const button = document.createElement('button')
button.onclick = increaseCounter
button.textContent = 'Increase counter'
container.appendChild(button)


// set up retool
const embedUrl = 'https://e2euseradmin.onretool.com/embedded/public/00bbd060-4543-46cd-983b-dd71eb60a08e'

embeddedRetool = createRetoolEmbed({
    src: embedUrl,
    style: "border: 1px solid blue; height: 98%; width: 100%;",
    data,
    onData: (data) => {
        const newDiv = document.createElement('div')
        newDiv.innerText = `Counter value: ${data}`
        container.appendChild(newDiv)
    }
});

container.appendChild(embeddedRetool)

